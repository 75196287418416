import {
  DateTime,
  DurationUnitValues,
  isGreaterThan0,
  Optional
} from "@laba/ts-common";
import { KnownDomainResourceLogicTag } from "model/primitives/domainResource";
import {
  ChargeItemStatus,
  ConsumptionGroup,
  ConsumptionGroupWithExtraData,
  ConsumptionItem
} from "model/resource/finance/consumption/consumption";
import { Code, Invoice } from "model/resource";
import { ModelReference } from "model/primitives";
import { isEqual, isUndefined } from "lodash-es";

export const getStartMonthOfOccurrenceConsumptionGroupOrUndefined = (
  consumptionGroupWithExtraData?: ConsumptionGroupWithExtraData
): Optional<DateTime> => {
  const occurrence =
    consumptionGroupWithExtraData?.consumptionGroup?.occurrence;
  return occurrence
    ? DateTime.fromApiDate(occurrence).startOf(DurationUnitValues.Month)
    : undefined;
};

export const consumptionGroupWithExtraDataHasConsumptionGroup = (
  consumptionGroupWithExtraData?: ConsumptionGroupWithExtraData
): boolean => !isUndefined(consumptionGroupWithExtraData?.consumptionGroup);

const consumptionGroupHasPatientDebt = (
  consumptionGroup?: ConsumptionGroup
): boolean => isGreaterThan0(consumptionGroup?.patientDebt?.value);

export const consumptionGroupWithExtraDataHasPatientDebt = (
  consumptionGroupWithExtraData?: ConsumptionGroupWithExtraData
): boolean =>
  consumptionGroupHasPatientDebt(
    consumptionGroupWithExtraData?.consumptionGroup
  );

export const isConsumptionGroupWithTransaction = (
  consumptionGroup?: ConsumptionGroup
): boolean =>
  consumptionGroup?.metaTagList?.includes(
    KnownDomainResourceLogicTag.ConsumptionWithTransaction
  ) ?? false;

export const isConsumptionGroupWithExtraDataWithTransaction = (
  consumptionGroup?: ConsumptionGroupWithExtraData
): boolean =>
  isConsumptionGroupWithTransaction(consumptionGroup?.consumptionGroup);

export const getConsumptionItemPatientPaymentCurrency = (
  item?: ConsumptionItem
): Optional<Code> => {
  return item?.patientPayment?.currency;
};

export const getConsumptionItemCoveragePaymentCurrency = (
  item?: ConsumptionItem
): Optional<Code> => {
  return item?.coveragePayment?.currency;
};

export const getConsumptionGroupPatientInvoiceReference = (
  consumptionGroupWithExtraData?: ConsumptionGroupWithExtraData
): Optional<ModelReference<Invoice>> => {
  return consumptionGroupWithExtraData?.patientInvoice;
};

export const hasConsumptionGroupPatientInvoice = (
  consumptionGroupWithExtraData?: ConsumptionGroupWithExtraData
): boolean => {
  return (
    getConsumptionGroupPatientInvoiceReference(
      consumptionGroupWithExtraData
    ) !== undefined
  );
};

export const isConsumptionGroupBudget = (
  consumptionGroup?: ConsumptionGroupWithExtraData
): boolean =>
  isEqual(consumptionGroup?.consumptionGroup?.status, ChargeItemStatus.planned);
