import {
  FC,
  getClassName,
  IconC,
  stopPropagationWrapper,
  StyleVariant,
  TextVariant
} from "@laba/react-common";
import { ContentCopyIcon } from "components/icons";
import { Snackbar } from "components/snackbar/Snackbar/Snackbar";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import React from "react";
import { Noop } from "@laba/ts-common";
import { useUrlLinkProps } from "components/link/UrlLink/utils";
import { UrlLinkIconSize, useUrlLinkStyle } from "./useUrlLinkStyle";

export enum OnClickUrlAction {
  CopyToClipboard = "CopyToClipboard",
  Redirect = "Redirect",
  CustomClick = "CustomClick"
}

export interface UrlLinkProps {
  withStartIcon?: boolean;
  StartIcon?: IconC;
  onClickUrlAction?: OnClickUrlAction;
  onClick?: Noop;
  url?: string;
  displayText?: string;
  underlineOnlyOnHover?: boolean;
  className?: string;
  snackbarText?: string;
  textVariant?: TextVariant;
  disabled?: boolean;
  multiline?: boolean;
  iconSize?: UrlLinkIconSize;
  textUnderline?: boolean;
  textStyleVariant?: StyleVariant;
}

export const UrlLink: FC<UrlLinkProps> = ({
  url,
  displayText,
  onClickUrlAction = OnClickUrlAction.Redirect,
  withStartIcon = false,
  StartIcon = ContentCopyIcon,
  underlineOnlyOnHover = false,
  className,
  snackbarText,
  onClick,
  textVariant = TextVariant.Body2,
  disabled = false,
  multiline = false,
  iconSize = UrlLinkIconSize.Medium,
  textUnderline = false,
  textStyleVariant
}) => {
  const classes = useUrlLinkStyle({
    textVariant,
    disabled,
    multiline,
    iconSize,
    textUnderline,
    textStyleVariant
  });
  const { closeSnackbar, wrappedOnClick, isSnackBarOpen } = useUrlLinkProps({
    onClick,
    onClickUrlAction,
    snackbarText,
    url
  });
  return (
    <>
      <ButtonBase
        className={getClassName(className, classes.root)}
        onClick={stopPropagationWrapper(wrappedOnClick)}
        disableRipple
        disabled={disabled}
      >
        {withStartIcon && <StartIcon className={classes.icon} />}
        <p
          className={getClassName(
            classes.text,
            underlineOnlyOnHover ? classes.underlinedOnHover : undefined
          )}
          title={displayText || url}
        >
          {displayText || url}
        </p>
      </ButtonBase>
      <Snackbar
        isOpen={isSnackBarOpen}
        style={StyleVariant.Success}
        text={snackbarText}
        onClose={closeSnackbar}
      />
    </>
  );
};
